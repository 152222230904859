import { Ref, ref } from 'vue'

export function useDragToScroll(): { scrollableArea: Ref; mouseDownHandler: (e: any) => void } {
	const scrollableArea = ref()
	const pos = ref({ top: 0, left: 0, x: 0, y: 0 })

	function mouseDownHandler(e) {
		scrollableArea.value.style.cursor = 'grabbing'
		scrollableArea.value.style.userSelect = 'none'

		pos.value = {
			left: scrollableArea.value.scrollLeft,
			top: scrollableArea.value.scrollTop,
			x: e.clientX,
			y: e.clientY,
		}

		document.addEventListener('mousemove', mouseMoveHandler)
		document.addEventListener('mouseup', mouseUpHandler)
	}
	function mouseMoveHandler(e) {
		const dx = e.clientX - pos.value.x
		const dy = e.clientY - pos.value.y

		scrollableArea.value.scrollTop = pos.value.top - dy
		scrollableArea.value.scrollLeft = pos.value.left - dx
	}
	function mouseUpHandler() {
		document.removeEventListener('mousemove', mouseMoveHandler)
		document.removeEventListener('mouseup', mouseUpHandler)

		scrollableArea.value.style.cursor = 'grab'
		scrollableArea.value.style.removeProperty('user-select')
	}

	return {
		scrollableArea,
		mouseDownHandler,
	}
}
