<script setup>
import { defineProps, useSlots, defineEmits } from 'vue'
import ItDialog from '@/components/common/ItDialog.vue'

const slots = useSlots()

defineProps({
	isVisible: {
		type: Boolean,
		required: true,
	},
	isLoading: {
		type: Boolean,
		required: false,
		default: false,
	},
	title: {
		type: String,
		required: true,
	},
	width: {
		type: Number,
		required: false,
		default: 420,
	},
	btnCancelText: {
		type: String,
		required: false,
		default: 'Нет',
	},
	btnConfirmText: {
		type: String,
		required: true,
	},
})

const emit = defineEmits(['close', 'confirm'])

function close() {
	emit('close')
}

function confirm() {
	emit('confirm')
}
</script>

<template>
	<ItDialog :is-visible="isVisible" :width="width" :title="title" @close="close">
		<div class="text-cod-gray">
			<slot name="default" />
		</div>

		<div class="flex items-center justify-end gap-4 mt-[70px]">
			<el-button size="medium" @click="close">{{ btnCancelText }}</el-button>
			<el-button size="medium" type="primary" :loading="isLoading" @click="confirm">
				{{ btnConfirmText }}
			</el-button>
		</div>

		<template v-if="slots.titleIcon" #titleIcon>
			<slot name="titleIcon" />
		</template>
	</ItDialog>
</template>
